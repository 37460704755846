<template>
  <div>
    <h3 class="mb-6 text-lg md:text-xl">Brand logo</h3>
    <div class="mb-4 sm:mb-8 md:mb-12">
      <p class="font-light text-sm md:text-base">
        Logo will appear on the top of your payment page when customers pay and
        self-manage their payment plans.
      </p>
    </div>
    <vs-row class="pt-2 pb-10">
      <vs-col class="w-full lg:w-5/6 md:flex md:flex-row">
      <small-radio-box
        class="w-full lg:w-2/5 md:mr-2 mb-4 md:mb-0"
        title="Use our logo"
        name="logoType"
        radioValue="useOurLogo"
        v-model="logo.logoType"
      >
      </small-radio-box>
      <small-radio-box
        class="w-full lg:w-2/5 md:mr-4"
        title="Upload your own logo"
        name="logoType"
        radioValue="uploadYourLogo"
        v-model="logo.logoType"
      >
      </small-radio-box>
      </vs-col>
    </vs-row>
    <hr class="mb-8 muted-horizontal-rule" v-if="logo.logoType !== `useOurLogo`" />
    <vs-row>
      <!-- USE OUR LOGO -->
      <div class="flex upload-img mb-16" v-if="logo.logoType == `useOurLogo`">
        <div class="flex">
          <div class="company-logo-wrap relative">
            <div>
              <template v-if="appName == 'FlipPay'">
                <div class="site-logo self-center">
                  <img :src="flipLogo" alt="Flippay" />
                </div>
              </template>

              <template v-else-if="appName == 'RelloPay'">
                <div class="site-logo self-center">
                  <img :src="relloLogo" alt="RelloPay" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>

      <vs-row v-if="logo.logoType !== `useOurLogo`">
        <div class="w-full mb-16">
          <div class="vx-col w-full vs-con-loading__container">
            <div class="flex upload-img" v-if="image">
              <!-- Image Container -->
              <div class="flex">
                <div class="company-logo-wrap relative">
                  <S3ImageGenerator
                    :is-base64="isBase64(image)"
                    :document="image"
                    :custom-class="'responsive'"
                    :key="image"
                  />
                  <span class="XCircleIcon">
                    <feather-icon
                      icon="XCircleIcon"
                      class="ml-1"
                      @click="clearImage"
                    />
                  </span>
                </div>
              </div>
            </div>

            <div class="flex upload-img" v-if="!image">
              <input
                type="file"
                name="companyLogo"
                v-validate="{
                  required: logo.logoType != 'useOurLogo' ? true : false,
                }"
                class="hidden"
                ref="uploadImgInput"
                @change="updateCurrImg"
                accept="image/png"
              />
              <div
                class="
                  force-btn-width
                  flex flex-col
                  md:flex-row
                  justify-content-start
                  flex-wrap
                  md:flex-nowrap
                  items-center
                "
              >
                <vs-button
                  flat
                  v-round
                  class="only-border-btn upicon mb-1"
                  size="medium"
                  type="border"
                  @click="$refs.uploadImgInput.click()"
                >
                  <template>
                    <upload-icon size="1.5x" class="custom-class"></upload-icon
                  ></template>
                  <u>Upload Image</u></vs-button
                >
              </div>
            </div>
            <span
              class="text-danger text-sm"
              v-show="errors.has('companyLogo')"
              >{{ errors.first("companyLogo") }}</span
            >
          </div>
        </div>
      </vs-row>
    </vs-row>
    <div class="actions text-right">
      <vs-button
        class="align-right w-48"
        size="large"
        v-round
        @click="next"
        >Next</vs-button
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Validator } from "vee-validate";
import SmallRadioBox from "@/views/components/smallRadioBox";
import { UploadIcon } from "vue-feather-icons";
import S3ImageGenerator from "@/views/components/S3ImageGenerator";

export default {
  components: { SmallRadioBox, UploadIcon, S3ImageGenerator },
  props: ["step", "logo"],
  data() {
    return {
      relloLogo: require("@/assets/images/logo/rellopay-logo.svg"),
      flipLogo: require("@/assets/images/logo/flippay-logo.svg"),
      appName: process.env.VUE_APP_NAME || "FlipPay",
      image: "",
      uploadLogo: "",
      step1: "",
    };
  },

  created() {
    if (this.logo.merchantLogo) {
      var reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(this.logo.merchantLogo);
    }
  },

  methods: {
    async next() {
      let valid = await this.$validator.validate();
      if (valid) {
        this.step1 = this.step + 1;
        this.$emit("increase", this.step1);
        if (this.logo.logoType === "useOurLogo") {
          this.logo.merchantLogo = this.uploadLogo;
        }
        if (this.logo.logoType !== "useOurLogo") {
          this.logo.merchantLogo = this.uploadLogo;
        }
      }
    },

    clearImage() {
      this.image = null;
    },

    isBase64(key) {
      if (typeof key !== "string") return false;
      const validation = new RegExp(/data:image/);
      return validation.test(key);
    },

    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.uploadLogo = input.target.files[0];

        var reader = new FileReader();
        reader.onload = (e) => {
          this.image = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
  },

  watch: {},
};
</script>