<template>
  <div>
    <h3 class="mb-6 text-lg md:text-xl">Assign bank accounts</h3>
    <div class="mb-4 sm:mb-8 md:mb-12">
      <p class="font-light text-sm md:text-base">
        Please nominate at least one bank account for each product below so that
        we can direct payments to the right account. A payment request template
        will be set up for each combination below so that you can easily send
        payment requests without having to select bank accounts each time.
      </p>
    </div>

    <div v-if="requestOptions.length == 1 && requestOptions.includes(`pay-now`)">
      <vs-card class="no-padding-card no-shadow" :key="i" v-for="(bankAccount, i) in bankAccountsList">
        <vs-row class="border rounded-lg border-slate-100 overflow-hidden">
          <vs-col vs-lg="4" vs-sm="12" class="bg-grey-background flex flex-row items-start">
            <div class="px-3 md:px-4 pt-3 pb-2">
              <h3 class="text-sm md:text-base text-dark-blue leading-normal mb-1">Pay now</h3>
              <p class="text-xs">Pay now product</p>
            </div>
          </vs-col>

          <vs-col vs-lg="8" vs-sm="12" class="flex flex-col accounts-list">
            <vs-checkbox class="ml-0 mt-0 pb-3" :vs-value="{bankAccount: bankAccount}" @change="checkBoxChecked(null, bankAccount)" :checked="checkAllBoxes">
              <div class="pt-4 pl-2">
                <h3 class="text-xs md:text-sm pb-1"> {{ bankAccount.displayName ? bankAccount.displayName : bankAccount.accountname }} </h3>
                <div class="flex flex-row">
                  <p class="text-xxs pr-6">BSB: {{ splitBsb(bankAccount.bsb) }}</p>
                  <p class="text-xxs">Account number: {{ bankAccount.accountNumber }}</p>
                </div>
              </div>
            </vs-checkbox>
            <hr class="line-hr"/>
          </vs-col>
        </vs-row>
      </vs-card>
    </div>

    <div v-if="requestOptions.includes(`pay-later`) && payLaterProductsList">
      <vs-card class="no-padding-card no-shadow" :key="index" v-for="(payLaterProduct, index) in payLaterProductsList">
        <vs-row class="border rounded-lg border-slate-100 overflow-hidden">
          <vs-col vs-lg="4" vs-sm="12" class="bg-grey-background flex flex-row items-start">
            <div class="px-3 md:px-4 pt-3 pb-2">
              <h3 class="text-sm md:text-base text-dark-blue leading-normal mb-1">{{ payLaterProduct.productName }}</h3>
              <p class="text-xs">{{ payLaterProduct.planId.productDescription }}</p>
            </div>
          </vs-col>

          <vs-col vs-lg="8" vs-sm="12" class="flex flex-col accounts-list">
            <div class="px-4 w-full" :key="i" v-for="(bankAccount, i) in bankAccountsList">
              <vs-checkbox class="ml-0 mt-0 pb-3" :vs-value="{bankAccount: bankAccount, payLaterProduct: payLaterProduct}" @change="checkBoxChecked(payLaterProduct, bankAccount)" :checked="checkAllBoxes">
                <div class="pt-4 pl-2">
                  <h3 class="text-xs md:text-sm pb-1"> {{ bankAccount.displayName ? bankAccount.displayName : bankAccount.accountname }}</h3>
                  <div class="flex flex-row">
                    <p class="text-xxs pr-6">BSB: {{ splitBsb(bankAccount.bsb) }}</p>
                    <p class="text-xxs">Account number: {{ bankAccount.accountNumber }}</p>
                  </div>
                </div>
              </vs-checkbox>
              <hr class="line-hr"/>
            </div>
          </vs-col>
        </vs-row>
      </vs-card>
    </div>

    <div class="actions text-right">
      <vs-button class="mt-8 align-right" style="width: 200px; height: 60px" v-round @click="next">Next</vs-button>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  props: ["step", "payLaterProductsList", "bankAccountsList", "combinations", "requestOptions"],
  data() {
    return {
      step4: "",
      checkAllBoxes: false,
    };
  },

  created() {
    if (this.bankAccountsList.length == 1 && this.requestOptions.includes(`pay-later`) && this.payLaterProductsList.length > 0) {
      this.checkAllBoxes = true;
      this.payLaterProductsList.forEach((payLaterProduct) => {
        this.combinations.push({
          bankAccount: this.bankAccountsList[0],
          payLaterProduct: payLaterProduct,
        });
      });
    }
  },

  methods: {
    async next() {
      if (!this.isComboRequired()) {
        this.showMessage(
          "Error",
          "Please select atleast one bank account for each product",
          "danger",
          "icon-times"
        );
        return;
      }
      this.step4 = this.step + 1;
      this.$emit("increase", this.step4);
    },

    splitBsb(value) {
      return value.substring(0, 3) + "-" + value.substring(3);
    },

    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    checkBoxChecked(payLaterProduct, bankAccount) {
      if (payLaterProduct) {
        const index = this.combinations.findIndex((e) => {
        return (e.payLaterProduct == payLaterProduct && e.bankAccount == bankAccount);
         });
        if (index < 0) {
          this.combinations.push({ payLaterProduct, bankAccount });
        } else {
          this.combinations.splice(index, 1);
        }
      } else {
        const index = this.combinations.findIndex((e) => e.bankAccount == bankAccount);
        if (index < 0) {
          this.combinations.push({bankAccount});
        } else {
          this.combinations.splice(index, 1);
        }
      }
  
    },

    isComboRequired() {
      const payLaterProductsListFromCombinations = this.combinations.map((e) => e.payLaterProduct).filter(e => e);
      const bankListFromCombinations = this.combinations.map((e) => e.bankAccount);
      const checker = (arr, target) => target.every((v) => arr.includes(v));
      if (payLaterProductsListFromCombinations.length) {
        return checker(payLaterProductsListFromCombinations, this.payLaterProductsList);
      } else {
        return checker(bankListFromCombinations, this.bankAccountsList);
      }
    },
  },

  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
};
</script>