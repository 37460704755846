<template>
  <div
    class="radiobox payment-page-radios radio-default"
    :class="{ 'radio-active': isActive }"
  >
    <div :class="{ 'background-active': isActive }" >
      <div class="radio-wrapper" :class="{ 'background-active': isActive }">
        <p class="font-normal capitalize text-sm leading-tight md:text-sm md:leading-normal">{{ title }}</p>
        <vs-radio
          v-model="model"
          :vs-name="name"
          :vs-value="radioValue"
          class="radio"
          :disabled="disabled"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "smallradiobox",
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    radioValue: {
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    reverse: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isActive() {
      return this.model === this.radioValue;
    },
  },
  watch: {
    value(val) {
      this.$emit("change", val);
    },
  },
};
</script>

<style scoped>
.radio-wrapper {
  background: transparent;
  display: flex;
  justify-content: space-between;
}
.radio-wrapper h5 {
  flex: 1 0 70%;
}

.description {
  border-top: 1px solid #bdbdbd;
}

.radio {
  flex: 0 0 10%;
}

.radiobox {
  background: #f8f8fb;
  /* border-radius: 0.5rem; */
  border-radius: 8px;
}

.radio-default {
  border: 1px solid #bdbdbd;
}

.radio-active {
  border: 4px solid #0f67f4;
}
#rellopay .radio-active {
  border-color: #006cac;
}

.background-active {
  background: #eaf2ff;
}
</style>
