<template>
  <div>
    <h3 class="mb-6 text-lg md:text-xl">Contact details</h3>
    <div class="mb-6 sm:mb-8 md:mb-12">
    <p class="font-light text-sm md:text-base">
      Your customers will see the below contact details when they receive
      payment requests. We’ve pre-filled it for you based on information
      captured during onboarding - you can edit if you wish.
    </p>
    </div>
    <vs-row>
      <vs-col vs-w="4" vs-lg="6" vs-xs="12">
        <div class="mb-8 sm:mr-4 md:mr-8 lg:mr-12">
          <label class="font-light label text-sm md:text-base text-dark-blue">Name</label>
          <vs-input
            class="w-full"
            v-validate="'required'"
            size="large"
            v-model="contactDetails.contactName"
            name="contactName"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('contactName')"
            >{{ errors.first("contactName") }}</span
          >
        </div>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col vs-w="4" vs-lg="6" vs-xs="12">
        <div class="mb-8 sm:mr-4 md:mr-8 lg:mr-12">
          <label class="font-light label text-sm md:text-base text-dark-blue">Email</label>
          <vs-input
            class="w-full"
            v-validate="'required|email'"
            size="large"
            v-model="contactDetails.email"
            name="email"
          />
          <span class="text-danger text-sm" v-show="errors.has('email')">{{
            errors.first("email")
          }}</span>
        </div>
      </vs-col>

      <vs-col vs-w="4" vs-lg="6" vs-xs="12">
        <div class="mb-8 md:ml-8 lg:ml-12">
          <label class="font-light label text-sm md:text-base text-dark-blue">Phone</label>
          <br />
          <the-mask
            :class="
              contactDetails.phoneNumber
                ? 'vs-inputx vs-input--input large'
                : 'vs-inputx vs-input--input large'
            "
            :mask="[checkPhoneNumber]"
            v-model="contactDetails.phoneNumber"
            masked
            name="phoneNumber"
            ref="phoneNumber"
            v-validate="'phoneFormat|required'"
          />
          <br />
          <span
            class="text-danger text-sm"
            v-show="errors.has('phoneNumber')"
            >{{ errors.first("phoneNumber") }}</span
          >
        </div>
      </vs-col>
    </vs-row>
    <div class="actions text-right">
      <vs-button
        class="mt-8 align-right w-48"
        v-round
        size="large"
        @click="next"
        >Next</vs-button
      >
    </div>
  </div>
</template>

<script>
import { Validator } from "vee-validate";
import { TheMask } from "vue-the-mask";

export default {
  components: { TheMask },
  props: ["step", "contactDetails"],
  data() {
    return {};
  },

  created() {
    this.$validator.extend("phoneFormat", {
      getMessage: () => "The phone number format is invalid.",
      validate: (value) =>
        value.match(
          /^(^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})|(^1300[\s.-]\d{3}[\s.-]\d{3})|(^1800[\s.-]\d{3}[\s.-]\d{3})|(^13[\s.-]\d{2}[\s.-]\d{2}$)|(^02[\s.-]\d{4}[\s.-]\d{4})|(^03[\s.-]\d{4}[\s.-]\d{4})|(^07[\s.-]\d{4}[\s.-]\d{4})|(^08[\s.-]\d{4}[\s.-]\d{4})/
        ) !== null,
    });
  },

  methods: {
    async next() {
      let valid = await this.$validator.validate();
      if (valid) {
        this.step2 = this.step + 1;
        this.$emit("increase", this.step2);
      }
    },
  },

  computed: {
    validateForm() {
      return !this.errors.any();
    },

    checkPhoneNumber() {
      let mask = "#### ### ###";
      if (
        this.contactDetails.phoneNumber &&
        this.contactDetails.phoneNumber.length > 0
      ) {
        let numLength = this.contactDetails.phoneNumber.length;
        let value1 = "";
        let value2 = "";
        if (numLength >= 2) {
          value1 = this.contactDetails.phoneNumber.substring(0, 2);
          if (value1 === "13") {
            value2 = this.contactDetails.phoneNumber.substring(0, 4);
          }
        }
        if (
          value1 === "02" ||
          value1 === "03" ||
          value1 === "07" ||
          value1 === "08"
        ) {
          mask = "## #### ####";
        } else {
          mask = "#### ### ###";
        }

        if (value1 === "13") {
          if (
            parseInt(value2) >= 1300 ||
            value2 === "130" ||
            value2 === "13 0"
          ) {
            mask = "#### ### ###";
          } else {
            mask = "## ## ##";
          }
        }
      }
      return mask;
    },
  },

  async beforeMount() {},

  watch: {},
};
</script>