<template>
  <div>
    <h3 class="mb-6 text-lg md:text-xl">Name templates</h3>
    <div class="mb-4 sm:mb-8 md:mb-12">
      <p class="font-light text-sm md:text-base">
        Set a short name for each payment request template configuration below so
        that you can easily identify which payment template to use.
      </p>
    </div>

    <div class="pb-4" v-if="requestOptions.length == 1 && requestOptions.includes(`pay-now`)">
      <vs-card :key="index" v-for="(combo, index) in combinations" class="no-padding-card no-shadow">
        <vs-row class="border rounded-lg border-slate-100 overflow-hidden">
          <vs-col vs-w="6" vs-md="6" vs-sm="12">
            <div class="mt-4 mb-8 ml-3 sm:ml-4 md:ml-6 mr-3 sm:mr-4 md:mr-6">
              <label class="label text-base text-dark-blue block mb-1">Template name</label>
              <vs-textarea
                class="w-full small-textarea"
                counter="30"
                maxlength="30"
                name="templateName"
                v-model="combo.templateName"
                v-validate="'required|max:30'"
                data-vv-as="template name"
                id="templateName"
                placeholder="e.g. Property maintenance"
              ></vs-textarea>
              <span class="text-danger text-sm" v-show="errors.has('templateName')">{{ errors.first("templateName") }}</span>
            </div>
          </vs-col>

          <vs-col vs-w="6" vs-md="6" vs-sm="12" class="gray">
            <div class="mb-3 sm:mb-4 md:mb-6 mt-4 mx-3 sm:mx-4 md:mx-6">
              <vs-row>
                <div class="flex flex-row items-center mb-4">
                  <span class="material-icons mr-4 text-gray"> category </span>
                  <p class="text-sm text-body-dark">Pay now product</p>
                </div>
              </vs-row>
              <vs-row>
                <div class="flex flex-row items-center">
                  <span class="material-icons mr-4 text-gray"> account_balance </span>
                  <p class="text-sm text-body-dark">{{ combo.bankAccount.displayName ? combo.bankAccount.displayName : combo.bankAccount.accountName }}</p>
                </div>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>
      </vs-card>
    </div>

    <div class="pb-4" v-if="requestOptions.includes(`pay-later`)">
      <vs-card :key="index" v-for="(combo, index) in combinations" class="no-padding-card no-shadow">
        <vs-row class="border rounded-lg border-slate-100 overflow-hidden">
          <vs-col vs-w="6" vs-md="6" vs-sm="12">
            <div class="mt-4 mb-8 ml-3 sm:ml-4 md:ml-6 mr-3 sm:mr-4 md:mr-6">
              <label class="label text-base text-dark-blue block mb-1">Template name</label>
              <vs-textarea
                class="w-full small-textarea"
                counter="30"
                maxlength="30"
                name="templateName"
                v-model="combo.templateName"
                v-validate="'required|max:30'"
                data-vv-as="template name"
                id="templateName"
                placeholder="e.g. Property maintenance"
              ></vs-textarea>
              <span class="text-danger text-sm" v-show="errors.has('templateName')">{{ errors.first("templateName") }}</span>
            </div>
          </vs-col>

          <vs-col vs-w="6" vs-md="6" vs-sm="12" class="gray">
            <div class="mb-3 sm:mb-4 md:mb-6 mt-4 mx-3 sm:mx-4 md:mx-6">
              <vs-row>
                <div class="flex flex-row items-center mb-4">
                <span class="material-icons mr-4 text-gray"> category </span>
                <p class="text-sm text-body-dark">{{ combo.payLaterProduct.productName }}</p>
              </div>
              </vs-row>
              <vs-row>
              <div class="flex flex-row items-center">
                <span class="material-icons mr-4 text-gray"> account_balance </span>
                <p class="text-sm text-body-dark">{{ combo.bankAccount.displayName ? combo.bankAccount.displayName : combo.bankAccount.accountName }}</p>
              </div>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>
      </vs-card>
    </div>

    <div class="actions text-right">
      <vs-button class="mt-8 align-right" style="width: 200px; height: 60px" v-round @click="finish">Finish</vs-button>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  props: ["combinations", "requestOptions"],
  data() {
    return {};
  },

  created() {},

  methods: {
    async finish() {
      let valid = await this.$validator.validate();

      if (valid) {
        this.$emit("finish-event");
      }
    },
  },

  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
};
</script>