<template>
  <div>
    <vs-card class="max-w-xl mx-auto mt-12 p-0 no-padding-card" style="max-width: 872px !important">
      <!-- card header -->
      <div class="bg-grey-background mx-auto py-6 px-4 rounded-tl-lg rounded-tr-lg">
        <h3 class="text-xl md:text-2xl text-center text-dark">Quick setup</h3>
        <!-- step progress code here -->
      </div>
        <div>
          <section>
            <o-steps v-model="step" :animated="isAnimated" :rounded="isRounded" :has-navigation=false :icon-prev="prevIcon" :icon-next="nextIcon" :label-position="labelPosition">
              <!-- welocme page -->
              <div v-if="!letsGoClicked">
                <h3 class="mb-4 md:mb-6 text-lg md:text-xl">Welcome!</h3>
                <p class="font-light text-sm md:text-base mb-2">To get started, you need to configure some essential settings for your account.</p>
                <p class="font-light text-sm md:text-base mb-4">This process should take around 5 minutes.</p>
                <div class="actions text-right">
                  <vs-button class="mt-12 sm:mt-10 md:mt-16 align-right w-48" v-round size="large" @click="letsGo">Let’s go!</vs-button>
                </div>
              </div>

              <o-step-item step="1" label="Brand  logo" :clickable="isStepsClickable" :variant="isProfileSuccess ? 'success' : ''">
                <!-- BRAND LOGO -->
                <div v-if="letsGoClicked && step == 1">
                  <brand-logo :step="step" @increase="increase" :logo="logo"></brand-logo>
                </div>
              </o-step-item>

              <o-step-item step="2" label="Contact details" :clickable="isStepsClickable">
                <!-- CONTACT  DETAILS -->
                <div v-if="letsGoClicked && step == 2">
                  <contact-details :step="step" @increase="increase" :contactDetails="contactDetails"></contact-details>
                </div>
              </o-step-item>

              <o-step-item step="3" label="Communications sender" :clickable="isStepsClickable">
                <!-- COMMUNICATIONS SENDER -->
                <div v-if="letsGoClicked && step == 3">
                  <communications-sender :step="step" @increase="increase" :commsSender="commsSender"></communications-sender>
                </div>
              </o-step-item>

              <o-step-item step="4" label="Assign bank accounts" :clickable="isStepsClickable">
                <!-- ASSIGN BANK ACCOUNTS -->
                <div v-if="letsGoClicked && step == 4">
                  <bank-accounts
                    :step="step"
                    @increase="increase"
                    :payLaterProductsList="payLaterProductsList"
                    :bankAccountsList="bankAccountsList"
                    :requestOptions="requestOptions"
                    :combinations="combinations">
                  </bank-accounts>
                </div>
              </o-step-item>

              <o-step-item step="5" label="Name templates" :clickable="isStepsClickable" disabled>
                <!-- NAME TEMPLATES -->
                <div v-if="letsGoClicked && step == 5">
                  <name-templates :combinations="combinations" :requestOptions="requestOptions" @finish-event="finishQuickSetup"></name-templates>
                </div>
              </o-step-item>
            </o-steps>
          </section>
        </div>
    </vs-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BrandLogo from "./brandLogoPage.vue";
import ContactDetails from "./contactDetails.vue";
import CommunicationsSender from "./communicationsSender.vue";
import BankAccounts from "./bankAccounts.vue";
import NameTemplates from "./nameTemplates.vue";
import { VStepper } from "vue-stepper-component";
import Vue from "vue";
import Oruga from "@oruga-ui/oruga";
import "@oruga-ui/oruga/dist/oruga.css";
import { sentryLog } from '../../../../../../admin/src/helper/sentryLog';

Vue.use(Oruga);
export default {
  components: {
    VStepper,
    BrandLogo,
    ContactDetails,
    CommunicationsSender,
    BankAccounts,
    NameTemplates,
    Oruga,
  },
  data() {
    return {
      letsGoClicked: false,
      steps: 5,
      step: 0,
      payLaterProductsList: [],
      bankAccountsList: [],
      combinations: [],
      globalMerchantSettings: {},
      requestOptions:[],

      // DATA TO STORE
      logo: {
        logoType: "useOurLogo",
        merchantLogo: null,
      },
      contactDetails: {
        contactName: "",
        email: "",
        phoneNumber: "",
      },
      commsSender: {
        smsSenderName: ``,
        emailSenderName: ``,
      },

      activeStep: 1,

      showSocial: false,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: false,

      hasNavigation: true,
      customNavigation: true,
      isProfileSuccess: false,

      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      labelPosition: "bottom",
    };
  },

  created() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));

    if (!userInfo) {
      this.$router.push({ path: "/login" });
    }

    if (userInfo.type == "partner" || (userInfo.partner && userInfo.partner.quickStartCompleted)) {
      this.$router.push("/dashboard");
    }

    this.getPayLaterProducts(this.partnerId);
    this.getBankByPartnerId(this.partnerId);
    this.getGlobalMerchantSettings(this.partnerId);
  },

  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
    partnerId() {
      return this.user.userType === "admin" ? this.user._id : this.user.partnerId;
    },
  },

  methods: {
    ...mapActions("partner", ["getPayLaterProductsByPartnerId", "submitQuickSetup"]),
    ...mapActions("bank", ["fetchBanksAccountByPartnerId"]),
    ...mapActions("merchantGlobalSetting", ["fetchGlobalMerchantSettings"]),

    async getGlobalMerchantSettings(partnerId) {
      try {
        let result = await this.fetchGlobalMerchantSettings(partnerId);
        if (result) {
          this.globalMerchantSettings = result.data.data;
          this.commsSender.smsSenderName = this.globalMerchantSettings.requestTemplateCommunication.smsSender;
          this.commsSender.emailSenderName = this.globalMerchantSettings.requestTemplateCommunication.emailSender;
          this.contactDetails.contactName = this.globalMerchantSettings.requestTemplateCommunication.fullname;
          this.contactDetails.email = this.globalMerchantSettings.requestTemplateCommunication.senderEmail;
          this.contactDetails.phoneNumber = this.globalMerchantSettings.requestTemplateCommunication.senderPhone;
        }
      } catch (ex) {
        sentryLog(ex);
      }
    },

    async getPayLaterProducts(partnerId) {
      try {
        let result = await this.getPayLaterProductsByPartnerId(partnerId);
        if (result) {
          this.requestOptions = result.data.data.requestOptions;
          this.payLaterProductsList = result.data.data.payLaterPlans;
        }
      } catch (ex) {
        sentryLog(ex);
      }
    },

    async getBankByPartnerId(partnerId) {
      try {
        let result = await this.fetchBanksAccountByPartnerId(partnerId);
        if (result) {
          this.bankAccountsList = result.data.data.filter((item) => item.isUsedForSettlement == true);
        }
      } catch (ex) {
        sentryLog(ex);
      }
    },

    async finishQuickSetup() {
      try {
        let formData = new FormData();
        formData.append("file", this.logo.merchantLogo);
        formData.append("contactDetails", JSON.stringify(this.contactDetails));
        formData.append("commsSender", JSON.stringify(this.commsSender));
        formData.append("combinations", JSON.stringify(this.combinations));
        formData.append("logoType", JSON.stringify(this.logo.logoType));

        let obj = {
          partnerId: this.partnerId,
          data: formData,
          config: {
            header: {
              "Content-Type": "multipart/form-data",
            },
          },
        };

        this.$vs.loading();
        let result = await this.submitQuickSetup(obj);
        if (result) {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Success",
            text: "Quick Setup Successfully Completed",
            iconPack: "feather",
            icon: "icon-check",
            color: "success",
          });
          let userFromLocalStorage = JSON.parse(
            localStorage.getItem("userInfo")
          );
          if (userFromLocalStorage && userFromLocalStorage.partner) {
            userFromLocalStorage.partner.quickStartCompleted = true;
            localStorage.removeItem("userInfo");
            localStorage.setItem(
              "userInfo",
              JSON.stringify(userFromLocalStorage)
            );
            this.$router.push({ name: "partner-dashboard" });
          }
        }
        if (!result) {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Failed",
            text: "Quick Setup Failed",
            iconPack: "feather",
            icon: "icon-cross",
            color: "danger",
          });
        }
      } catch (err) {
        this.$vs.loading.close();
        console.log(err);
      }
    },

    increase(val) {
      this.step = val;
    },

    brandLogo(val) {
      this.merchantLogo = val;
    },

    contactDetail(val) {
      this.contactDetails = val;
    },

    commsSenders(val) {
      this.commsSender = val;
    },

    letsGo() {
      this.letsGoClicked = true;
      this.step = 1;
    },
  },
};
</script>